<template>
  <el-row>
    <el-container style="height:calc(93.5vh);">
      <el-header class="header">
        <div class="header-btm">
          <div class="left">
            数据概览
          </div>
          <div class="right">
            <!-- <div class="tabs cursor">
              <div :class="tabsActive===1?'tab tab1':'tab'" @click="tabsActive=1">最近一周</div>
              <div :class="tabsActive===2?'tab tab1':'tab'" @click="tabsActive=2">最近30天</div>
              <div :class="tabsActive===3?'tab tab1':'tab'" @click="tabsActive=3">最近半年</div>
              <div :class="tabsActive===4?'tab tab1':'tab'" @click="tabsActive=4">最近1年</div>
            </div> -->
            <div class="data">
              <el-date-picker class="cursor" v-model="timePicker" type="daterange" start-placeholder="开始日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="-" end-placeholder="结束日期" :editable="false" align="center" prefix-icon="none"
                clear-icon="none">
              </el-date-picker>
            </div>
          </div>
        </div>
      </el-header>
      <el-container style="height:calc(93.5vh);">
        <el-container>
          <el-main class="main">
            <div class="content">
              <div class="f1">
                <el-row :gutter="10">
                  <el-col class="content-box" :span="rowCol" v-for="(item,i) in fOneCardList" :key="i">
                    <div class="card" :style="'border-color:'+item.color">
                      <div class="title">{{item.title}}</div>
                      <div class="price">{{item.price}}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="f2">
                <div class="title-tabs cursor">
                  <div :class="fTwoTabsActive===1?'tab tab1':'tab'" @click="fTwoTabsActive=1">营收分析</div>
                  <div :class="fTwoTabsActive===2?'tab tab1':'tab'" @click="fTwoTabsActive=2">新增会员</div>
                  <div :class="fTwoTabsActive===3?'tab tab1':'tab'" @click="fTwoTabsActive=3">订单数量</div>
                  <div :class="fTwoTabsActive===4?'tab tab1':'tab'" @click="fTwoTabsActive=4">充值金额</div>
                  <!-- <div :class="fTwoTabsActive===5?'tab tab1':'tab'" @click="fTwoTabsActive=5">商品销量</div> -->
                </div>
                <div id="main" style="width:100%;height: 500px;margin-top: 10px;">
                </div>
              </div>

              <!-- <div class="f3">
                <div class="title cursor">
                  充值类型
                </div>
                <el-row class="content-box" :gutter="30">
                  <el-col :span="fThreeRowCol" v-for="(item,i) in 4" :key="i">
                    <div class="type-card">
                      <div class="top">
                        <div class="top-l">银行卡</div>
                        <div class="top-r">
                          <span v-if="i!==1" class="el-icon-top"></span>
                          <span v-else class="el-icon-bottom"></span>
                        </div>
                      </div>
                      <div class="content">
                        869.48 <span>万</span>
                      </div>
                      <div class="bottom">
                        <el-progress v-if="i===0" :percentage="45" :text-inside="true" :stroke-width="16"
                          color="#fb6260"></el-progress>
                        <el-progress v-else-if="i===1" :percentage="45" :text-inside="true" :stroke-width="16"
                          color="#fec03d">
                        </el-progress>
                        <el-progress v-else-if="i===2" :percentage="45" :text-inside="true" :stroke-width="16"
                          color="#58a3f7">
                        </el-progress>
                        <el-progress v-else-if="i===3" :percentage="45" :text-inside="true" :stroke-width="16"
                          color="#4bced0">
                        </el-progress>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div> -->

              <!-- <div class="f4">
                <el-row :gutter="30">
                  <el-col :span="fFourRowCol">
                    <div class="f4-box">
                      <div class="title cursor">
                        其他数据
                      </div>
                      <div class="box">
                        <div class="top">
                          <div class="t-l">
                            <div class="l-title">
                              积分发放
                            </div>
                            <div class="t-num">
                              10000
                            </div>
                          </div>
                          <div class="t-l">
                            <div class="l-title">
                              积分消耗
                            </div>
                            <div class="t-num">
                              10000
                            </div>
                          </div>
                        </div>
                        <div class="top">
                          <div class="t-l">
                            <div class="l-title">
                              退款金额
                            </div>
                            <div class="t-num">
                              2000.00
                            </div>
                          </div>
                          <div class="t-l">
                            <div class="l-title">
                              充值笔数
                            </div>
                            <div class="t-num">
                              1000
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </el-col>
                  <el-col :span="fFourRowCol">
                    <div class="f4-box">
                      <div class="title cursor">
                        消费分析
                      </div>
                      <div class="box">
                        <div class="top">
                          <div class="t-l">
                            <div>
                              <el-progress type="circle" :stroke-width="10" color="#ff7a8c" :percentage="25">
                              </el-progress>
                            </div>
                            <div class="l-title">
                              新客户
                            </div>
                          </div>
                          <div class="t-l">
                            <div>
                              <el-progress type="circle" :stroke-width="10" :percentage="25"></el-progress>
                            </div>
                            <div class="l-title">
                              老客户
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div> -->

              <div class="f5">
                <el-row :gutter="30">
                  <el-col :span="fFourRowCol">
                    <div class="f5-box">
                      <div class="title cursor">
                        商品销量TOP15
                      </div>
                      <el-table :data="shopstableData" stripe style="width: 100%">
                        <el-table-column prop="id" label="排名" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="商品名称" width="180">
                        </el-table-column>
                        <el-table-column prop="sale" label="销量">
                        </el-table-column>
                        <el-table-column prop="sum" label="金额">
                        </el-table-column>
                      </el-table>

                    </div>
                  </el-col>
                  <el-col :span="fFourRowCol">
                    <div class="f5-box">
                      <div class="title cursor">
                         服务销量TOP15
                      </div>
                      <el-table :data="servertableData" stripe style="width: 100%">
                        <el-table-column prop="id" label="排名" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="服务名称" width="180">
                        </el-table-column>
                        <el-table-column prop="sale" label="销量">
                        </el-table-column>
                        <el-table-column prop="sum" label="金额">
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-col>
                </el-row>
              </div>

            </div>
          </el-main>
        </el-container>
      </el-container>
      <!-- <el-footer class="footer">

      </el-footer> -->
    </el-container>
  </el-row>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    data() {
      return {
        datainfo:{},
        charts: '',
        tabsActive: 1,
        innerWidth: document.documentElement.clientWidth,
        timePicker: [],
        rowCol: 6,
        fThreeRowCol: 6,
        fFourRowCol: 12,
        fOneCardList: [{
            title: '主营收入(元)',
            price: '20000.00',
            color: '#ff7a8c'
          },
          {
            title: '售卡金额(元)',
            price: '20000.00',
            color: '#0099ff'
          },
          {
            title: '充值金额(元)',
            price: '20000.00',
            color: '#8167f5'
          }
          // {
          //   title: '耗卡金额(元)',
          //   price: '20000.00',
          //   color: '#51d351'
          // },
          // {
          //   title: '完成订单(元)',
          //   price: '20000.00',
          //   color: '#ffa94c'
          // },
          // {
          //   title: '新增会员(元)',
          //   price: '20000.00',
          //   color: '#ff5c00'
          // },
          // {
          //   title: '销售商品(元)',
          //   price: '20000.00',
          //   color: '#52c1f5'
          // },
          // {
          //   title: '提成金额(元)',
          //   price: '20000.00',
          //   color: '#7585a2'
          // },
        ],
        fTwoTabsActive: 1,
        shopstableData: [],
        servertableData: []
      }
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
          this.fThreeRowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
          this.fFourRowCol = 12
        } else if (val >= 830) {
          this.rowCol = 12
          this.fThreeRowCol = 12
          this.fFourRowCol = 24
        } else if (val <= 830) {
          this.rowCol = 12
        }
      },
      fTwoTabsActive(){
        this.getData()
      },
      timePicker(){
        this.getData()
      }
    },
    created () {
      this.getData()
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        that.rowCol = 6
        that.fThreeRowCol = 6
      } else if (that.innerWidth >= 1150) {
        that.rowCol = 8
        that.fFourRowCol = 12

      } else if (that.innerWidth >= 830) {
        that.rowCol = 12
        that.fThreeRowCol = 12
        that.fFourRowCol = 24
      } else if (that.innerWidth <= 830) {
        that.rowCol = 12
      }
      window.onresize = function () {
        window.fullWidth = document.documentElement.clientWidth;
        that.innerWidth = window.fullWidth; // 宽
        echarts.init(document.getElementById("main")).resize();
      }
      // that.$nextTick(function () {
      //   // that.drawLine('main')
      //   // this.zhuzhang('main')
      // })
    },
    methods: {
      //获取营业额统计信息
      getData(){
        this.$http.post('api/store/census/income',{
          time1:this.timePicker[0]?this.timePicker[0]:'',
          time2:this.timePicker[1]?this.timePicker[1]:''
        }).then(res =>{
          this.fOneCardList[0].price = res.data.data.allyingye
          this.fOneCardList[1].price = res.data.data.cardsummoney
          this.fOneCardList[2].price = res.data.data.recrargemoney
          this.shopstableData = res.data.data.shops
          this.servertableData = res.data.data.server
          let datas
          let type = []
          let typename = {}
          if(this.fTwoTabsActive == 1){//营业额
            datas = res.data.data.a
            typename = {data: ["营业额"]}
            type = [
            { 
              name: '营业额', 
              type: 'line', 
              barWidth: '10%', 
              data: datas[1]
              }
            ]
          }else if(this.fTwoTabsActive == 2){//会员数量
            datas = res.data.data.b
            typename = {data: ["会员数量"]}
            type = [
            { 
              name: '会员数量', 
              type: 'line', 
              barWidth: '10%', 
              data: datas[1]
              }
            ]
          }else if(this.fTwoTabsActive == 3){//订单数量
            datas = res.data.data.c
            typename = {data: ["卡券订单(个)","商品订单(个)","服务订单(个)","充值订单(个)","积分订单(个)","面诊订单(个)"]}
            type = [
              { 
              name: '卡券订单', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[1]
              },
              { 
              name: '商品订单(个)', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[2]
              },
              { 
              name: '服务订单(个)', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[3]
              },
              { 
              name: '充值订单(个)', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[4]
              },
              { 
              name: '积分订单(个)', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[5]
              },
              { 
              name: '面诊订单(个)', 
              type: 'bar', 
              barWidth: '10%', 
              data: datas[6]
              }
            ]
          }else if(this.fTwoTabsActive == 4){//充值
            datas = res.data.data.d
            typename = {data: ["充值金额"]}
            type = [
            { 
              name: '充值金额', 
              type: 'line', 
              barWidth: '10%', 
              data: datas[1]
              }
            ]
          }
          let yeobj = {
            tooltip: {trigger: 'axis',axisPointer: {type: 'shadow'}}, 
            grid: {left: '3%',right: '4%',bottom: '3%',containLabel: true}, 
            xAxis: [{type: 'category',data: datas[0],axisTick: {alignWithLabel: true}}], 
            yAxis: [{type: 'value'}], 
            legend: typename,
            series: type
          }
          this.datainfo = yeobj
          this.zhuzhang('main')
        })
      },
      //生成折现图
      zhuzhang(id){
        var myChartser = echarts.init(document.getElementById(id)); 
        var optionser; 
        optionser =  this.datainfo
        myChartser.setOption(optionser,true);
      },
      //原始折线图dome
      drawLine(id) {
        this.charts = echarts.init(document.getElementById(id));
        this.charts.setOption({
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },

          xAxis: {
            boundaryGap: false, //坐标轴两边留白
            data: ['12-01', '12-02', '12-03', '12-04', '12-05', '12-06', '12-07'],
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
              // margin:15,
              color: '#1B253A',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,

            },

            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: '#E5E9ED',

              },
            },

          },
          yAxis: [{
            type: 'value',
            splitNumber: 10,
            axisLabel: {
              color: '#a8aab0',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },

          }, ],
          series: [{
            smooth: true,
            type: 'line',
            itemStyle: {
              color: 'rgba(64, 158, 255,1)',
            },
            lineStyle: {
              color: 'rgba(64, 158, 255,1)',
              width: 1,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: 'rgba(64, 158, 255,0)',
                },
                {
                  offset: 1,
                  color: 'rgba(64, 158, 255,0.35)',
                },
              ]),
            },
            data: [900, 500, 7, 800, 60, 700, 800],
          }, ],
        })
      }
    }
  }

</script>

<style lang="less" scoped>
  .header {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;

    .header-btm {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        font-weight: bold;
      }

      .right {
        display: flex;
        justify-content: flex-end;

        .tabs {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .tab {
            box-sizing: border-box;
            padding: 5px 10px;
            border: 1px solid #eee;
            border-left: none;

            &:first-child {
              border-radius: 5px 0 0 5px;
              border-left: 1px solid #e4e4e4;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
            }
          }

          .tab1 {
            box-sizing: border-box;
            background-color: #409eff;
            border: 1px solid #409eff;

            color: #fff;
          }
        }

        .data {
          margin-top: 5px;

          /deep/ .el-input__inner {
            border: 0;
            background-color: transparent;
            text-align: center;
            width: 200px !important;

            .el-range-input {
              width: 50%;
            }

            .el-input__icon {
              width: 0;
            }
          }
        }
      }
    }
  }

  .main {

    .content {
      padding: 20px 10px 50px;

      .f1 {
        .content-box {
          .card {
            line-height: 30px;
            background-color: #fff;
            margin-bottom: 15px;
            padding: 10px;
            border-left: 4px solid;
            box-shadow: 0 0 3px #ccc;

            .title {
              font-size: 12px;
              color: #999;
            }

            .price {
              font-size: 14px;
              font-weight: bold;
              color: #666;
            }
          }
        }
      }

      .f2 {
        box-shadow: 0 0 4px #ccc;
        background-color: #fff;

        .title-tabs {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 50px;
          background-color: #f9f9f9;
          padding: 0 20px;

          .tab {
            padding: 0 20px;
            border-bottom: 3px solid transparent;
            box-sizing: border-box;
          }

          .tab1 {
            color: #409eff;
            border-bottom: 3px solid #409eff;
            box-sizing: border-box;
          }
        }
      }

      .f3 {
        box-shadow: 0 0 4px #ccc;
        background-color: #fff;
        margin-top: 40px;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 50px;
          background-color: #f9f9f9;
          padding: 0 20px;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid #f2f2f2;
        }

        .content-box {
          padding: 20px;

          .type-card {
            padding: 10px;

            .top {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .top-l {
                color: #666;
              }

              .top-r {

                .el-icon-top {
                  font-size: 18px;
                  color: #fa716d;
                  font-weight: bold;
                }

                .el-icon-bottom {
                  font-size: 18px;
                  color: #4bd863;
                  font-weight: bold;
                }
              }
            }

            .content {
              padding: 10px 0;
              font-size: 24px;
              font-weight: bold;

              span {
                font-size: 16px;
                font-weight: normal;
              }
            }
          }
        }

      }

      .f4 {
        .f4-box {
          box-shadow: 0 0 4px #ccc;
          background-color: #fff;
          margin-top: 40px;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 50px;
            background-color: #f9f9f9;
            padding: 0 20px;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #f2f2f2;
          }

          .box {
            padding: 20px;
            height: 245px;
            box-sizing: border-box;

            .top {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin: 15px 0;

              .t-l {
                width: 50%;
                border-left: 1px solid #f2f2f2;
                text-align: center;
                line-height: 40px;

                &:first-child {
                  border-left: none;
                }

                .t-title {
                  font-size: 12px;
                  color: #999;
                }

                .t-num {
                  font-size: 28px;
                  color: #666;
                  font-weight: bold;
                }
              }
            }
          }

        }
      }

      .f5 {
        .f5-box {
          box-shadow: 0 0 4px #ccc;
          background-color: #fff;
          margin-top: 40px;

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 50px;
            background-color: #f9f9f9;
            padding: 0 20px;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #f2f2f2;
          }

          /deep/ .el-table__header-wrapper {
            .has-gutter {
              th {
                text-align: center;
              }
            }
          }

          /deep/.el-table__body-wrapper {
            .el-table_1_column_1,
            .el-table_2_column_4 {
              text-align: center;

              .cell {
                font-weight: bold;
                color: #fff;
                display: inline-block;
                background-color: #cccccc;
                border-radius: 5px;
              }
            }

            .el-table__row {
              &:nth-child(1) {

                .el-table_1_column_1,
                .el-table_2_column_4 {
                  .cell {
                    background-color: #ff9900;
                  }
                }
              }

              &:nth-child(2) {

                .el-table_1_column_1,
                .el-table_2_column_4 {
                  .cell {
                    background-color: #ff9900;
                  }
                }
              }

              &:nth-child(3) {

                .el-table_1_column_1,
                .el-table_2_column_4 {
                  .cell {
                    background-color: #ff9900;
                  }
                }
              }



              .cell {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  /deep/ .el-header {
    height: 50px !important;
  }

</style>
